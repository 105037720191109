import { ref, watch, onMounted, computed } from 'vue'
import { getShops, createShop, removeShop } from '@/services/shop.service'
import { getCountries } from '@/services/countries.service'
import { getCategories } from '@/services/categories.service'

export function useShops () {
  const shops = ref([])
  const countries = ref(null)
  const currentPage = ref(1)
  const logoPreview = ref(null)
  const searchQuery = ref('')
  const isLoading = ref(false)
  const categories = ref([])

  const computedCountries = computed(() => countries.value)
  const categoriesData = computed(() => categories.value)

  watch([searchQuery, currentPage], () => {
    fetchShops()
  })

  const onPageChange = (page) => {
    currentPage.value = page
  }

  const search = (e) => {
    // eslint-disable-next-line no-unused-vars
    const [input, ...rest] = e.target
    searchQuery.value = input.value
  }

  const onPickLogo = (e) => {
  // eslint-disable-next-line no-unused-vars
    const [file, ...rest] = e.target.files
    logoPreview.value = URL.createObjectURL(file)
  }

  const fetchCategories = async () => {
    const { data: categoriesData } = await getCategories()
    categories.value = categoriesData.data
  }

  const fetchShops = async () => {
    const params = {
      title: searchQuery.value,
      paginate: 10,
      page: currentPage.value
    }

    const shopsResponse = await getShops(params)
    shops.value = shopsResponse.data
  }

  const fetchCountries = async () => {
    const countriesResponse = await getCountries()
    countries.value = countriesResponse.data.data
  }

  const addShop = async (formValues) => {
    const formData = new FormData()

    Object.entries(formValues).forEach(([name, value]) => {
      formData.append(name, value)
    })

    await createShop(formData)
    await fetchShops()

    window.location.reload()
  }

  const deleteShop = async (id) => {
    if (confirm('delete ?')) {
      await removeShop(id)
      await fetchShops()
    }
  }

  onMounted(async () => {
    isLoading.value = true
    await fetchShops()
    await fetchCountries()
    await fetchCategories()
    isLoading.value = false
  })

  const tableHeaders = [
    'Название',
    'Страна',
    'Ссылка',
    'Действия'
  ]

  return {
    tableHeaders,
    shops,
    computedCountries,
    categoriesData,
    currentPage,
    searchQuery,
    logoPreview,
    isLoading,
    onPageChange,
    onPickLogo,
    search,
    addShop,
    deleteShop,
    fetchCategories
  }
}
