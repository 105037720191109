<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Магазины</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-shops"
                >
                  Добавить
                </button>
              </div>
<!--              <div class="col-md-4">-->
<!--                <div class="d-flex align-items-center justify-content-end">-->
<!--                  <select class="form-select me-2 w-50" @change="onSelectStatus">-->
<!--                    <option class="fst-italic" selected="" disabled="" value="">Выберите статус</option>-->
<!--                  </select>-->
<!--                  <button class="btn btn-primary">Применить</button>-->
<!--                </div>-->
<!--              </div>-->
              <form @submit.prevent="search" class="col-md-4 offset-md-4 d-flex align-items-center">
                <div class="form-group input-group mb-0">
                  <input v-model="searchQuery" type="search" class="form-control" placeholder="Search...">
                  <span class="input-group-text" id="basic-addon1">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></circle>
                        <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body px-0">
            <div class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders"
                      :key="index"
                      :class="{ 'text-end': tableHeaders.length - 1 === index }"
                  >
                    {{ header }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(shop, index) in shops.data" :key="index">
                  <td>
                    {{ shop.name }}
                  </td>
                  <td>{{ shop.country.name.uz }}</td>
                  <td>
                    <a :href="shop.link" target="_blank" class="text-decoration-underline">{{ shop.link }}</a>
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <router-link class="dropdown-item"
                                       :to="{ name: 'default.shops.edit', params: { id: shop.id } }"
                          >
                            Редактировать
                          </router-link>
                        </li>
                        <li>
                          <a class="dropdown-item text-danger" href="#" @click="deleteShop(shop.id)">Удалить</a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="shops?.pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="shops?.pagination?.total_pages || 1"
                :total="shops?.pagination?.total || 1"
                :per-page="2"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal ref="shopModal" mainClass="fade" :tabindex="-1" id="add-shops"
           ariaLabelled="addModalLabel" :ariaHidden="true"
           dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Добавление магазина</h1>

        <Form @submit="addShop" class="row mt-4" v-slot="{ meta }">
          <div class="col-md-6">
            <label for="name" class="form-label">Название</label>
            <Field name="name" class="form-control" label="Название" id="name" rules="required" />
            <ErrorMessage name="name" class="text-danger" />

            <label for="country_id" class="form-label mt-4">Страна</label>
            <Field id="country_id" name="country_id" type="number" class="form-select" as="select"
                   rules="required">
              <option v-for="(country, index) in computedCountries" :key="index" :value="country.id">
                {{ country.name.ru }}
              </option>
            </Field>
            <ErrorMessage class="text-danger" name="country_id" />

            <label for="category_id" class="form-label mt-4">Категория</label>
            <Field id="category_id" name="category_id" type="number" class="form-select" as="select"
                   rules="required">
              <option v-for="category in categoriesData" :key="category.id" :value="category.id">
                {{ category.name.ru }}
              </option>
            </Field>
            <ErrorMessage class="text-danger" name="category_id" />

            <label for="country-photo" class="form-label mt-4">Логотип</label>
            <Field
              id="country-photo"
              type="file"
              class="form-control"
              rules="required"
              name="image"
              @change="onPickLogo"
            />
            <ErrorMessage class="text-danger" name="image" />
          </div>

          <div class="col-md-6">
            <label for="link" class="form-label">Ссылка</label>
            <Field id="link" type="text" class="form-control" name="link" rules="required|url" />
            <ErrorMessage class="text-danger" name="link"></ErrorMessage>

            <label for="link" class="form-label mt-4">Предпросмотр логотипа</label>
            <div
              class="preview-logo"
              :style="`
                background-image: url('${logoPreview}');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
              `"
            ></div>
          </div>

          <div class="text-center mt-4">
            <button :disabled="!(meta.valid && meta.dirty)" type="submit" class="btn btn-primary">Сохранить</button>
          </div>
        </Form>

        <br><br>
      </model-body>
    </modal>
  </div>
</template>

<script>
import CustomPagination from '../../../components/custom/pagination/CustomPagination'
import modal from '../../../components/bootstrap/modal/modal'
import modelHeader from '../../../components/bootstrap/modal/model-header'
import modelBody from '../../../components/bootstrap/modal/model-body'
import { useShops } from './useShops'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  name: 'ShopIndex',
  setup () {
    const {
      countries,
      shops,
      computedCountries,
      categoriesData,
      tableHeaders,
      currentPage,
      searchQuery,
      logoPreview,
      isLoading,
      onPageChange,
      onPickLogo,
      search,
      addShop,
      deleteShop
    } = useShops()

    return {
      countries,
      shops,
      computedCountries,
      categoriesData,
      currentPage,
      tableHeaders,
      searchQuery,
      logoPreview,
      isLoading,
      onPageChange,
      onPickLogo,
      search,
      addShop,
      deleteShop
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    CustomPagination,
    modal,
    modelBody,
    modelHeader
  }
}
</script>

<style scoped>
.preview-logo {
  width: 310px;
  height: 194px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
</style>
